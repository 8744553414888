import React from 'react';
import {
  Field,
  Meta,
  Spacer,
  Image,
  Icon,
  FitText,
  Collection,
  ExternalLink,
} from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Drawer from 'components/Drawer';
import Button from 'components/Button';
import Deck from 'components/Deck';
import Container from 'components/Container';
import Carousel from 'components/Carousel';
import AppButton from 'components/AppButton';
import Expandable from 'components/Expandable';
import ColorShift from 'components/ColorShift';
import AppScreen from 'components/AppScreen';

import hero from 'assets/images/sets/hero';
import arrow from 'assets/images/arrow.svg';

import './home.less';

export default function Home() {
  const { getElementClass } = useClass('home');
  return (
    <div>
      <Meta>
        <title>
          Rove Nutrition | Virtual sessions with a dietitian covered by
          insurance
        </title>
      </Meta>
      <Header />
      <div className={getElementClass('hero')}>
        <Image set={hero} className={getElementClass('hero-image')} />
        <div className={getElementClass('hero-main-wrapper')}>
          <div className={getElementClass('hero-main-spacer')} />
          <div className={getElementClass('hero-main')}>
            <h3 className={getElementClass('hero-header')}>
              <Field name="tagline-header" />
            </h3>
            <h4 className={getElementClass('hero-tagline')}>
              <Field name="tagline" />
            </h4>
            <h5 className={getElementClass('hero-tagline-subtitle')}>
              <Field name="tagline-subtitle" />
            </h5>
            <Spacer />
            <div className={getElementClass('hero-buttons')}>
              <img
                className={getElementClass('hero-buttons-arrow')}
                src={arrow}
              />
              <AppButton to="/register" primary>
                Book a Free Consultation
              </AppButton>
              <div className={getElementClass('hero-buttons-prompt')}>
                <Field name="tagline-prompt" />
              </div>
            </div>
          </div>
          <div
            data-mobile
            className={getElementClass('hero-scroll-button')}
            onClick={scrollToRove}>
            <Icon name="angle-down" /> Scroll to Learn More
          </div>
        </div>
      </div>
      <div className={getElementClass('cards')}>
        <div id="discover-rove" className={getElementClass('card', 'top')}>
          <div className={getElementClass('card-image')}>
            <Image set={hero} />
          </div>
          <div className={getElementClass('card-inner', 'top')}>
            <h5 className={getElementClass('card-title')}>
              <Field name="card-1-title" />
            </h5>
            <div className={getElementClass('card-body')}>
              <div data-desktop>
                <ColorShift>Change is hard. Doing it alone is</ColorShift>
                <ColorShift>harder. Our dietitians are here to</ColorShift>
                <ColorShift>guide you every step of the way.</ColorShift>
              </div>
              <div data-mobile>
                <ColorShift>Change is hard. Doing it</ColorShift>
                <ColorShift>alone is harder.</ColorShift>
                <ColorShift>Our dietitians are here</ColorShift>
                <ColorShift>to guide you every step</ColorShift>
                <ColorShift>of the way.</ColorShift>
              </div>
            </div>
          </div>
        </div>
        <div className={getElementClass('card', 'middle')}>
          <div className={getElementClass('card-inner', 'middle')}>
            <h5 className={getElementClass('card-title')}>
              <Field name="card-2-title" />
            </h5>
            <div className={getElementClass('card-body')}>
              <div data-desktop>
                <ColorShift>We champion small, achievable</ColorShift>
                <ColorShift>goals to make weight loss</ColorShift>
                <ColorShift>stress-free, easy and rewarding.</ColorShift>
              </div>
              <div data-mobile>
                <ColorShift>We champion small,</ColorShift>
                <ColorShift>achievable goals to</ColorShift>
                <ColorShift>make weight loss</ColorShift>
                <ColorShift>stress-free, easy and</ColorShift>
                <ColorShift>rewarding.</ColorShift>
              </div>
            </div>
          </div>
        </div>
        <div id="our-network" className={getElementClass('card', 'bottom')}>
          <div className={getElementClass('card-inner', 'bottom')}>
            <h5 className={getElementClass('card-title')}>
              <Field name="card-3-title" />
            </h5>
            <div className={getElementClass('card-body')}>
              <div data-desktop>
                <ColorShift>Insurance pays the bills</ColorShift>
                <ColorShift>so you have one less thing</ColorShift>
                <ColorShift>to worry about.</ColorShift>
              </div>
              <div data-mobile>
                <ColorShift>Insurance pays the bills</ColorShift>
                <ColorShift>so you have one less</ColorShift>
                <ColorShift>thing to worry about.</ColorShift>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacer size="xl" />
      <Carousel id="how-it-works" header="How it works" style="arrows">
        <Collection
          name="works"
          fields={{
            image: 'image@420w',
            title: 'string',
            body: 'string',
          }}
          sizes={{
            image: '(max-width: 700px) 90vw, 40vw',
          }}
          renderItem={(item, data) => {
            const { image, title, body } = item;
            const { number } = data;
            return (
              <div className={getElementClass('step')}>
                <div className={getElementClass('step-image')}>
                  {image}
                  <div className={getElementClass('step-number')}>
                    {number.toString().padStart(2, '0')}
                  </div>
                </div>
                <Spacer size="md" />
                <div className={getElementClass('step-body')}>
                  <p className="bold">{title}</p>
                  <Spacer size="xs" />
                  <p>{body}</p>
                </div>
              </div>
            );
          }}
        />
      </Carousel>
      <Spacer size="xl" />
      <Container>
        <div id="our-expertise" className={getElementClass('split')}>
          <Field
            name="split-image"
            type="image@500w"
            sizes="(max-width: 700px) 90vw, 40vw"
            className={getElementClass('split-image')}
          />
          <div className={getElementClass('split-content')}>
            <div className={getElementClass('split-title')}>Our Expertise</div>
            <div className={getElementClass('split-body')}>
              <Field name="split-body" />
            </div>
            <Spacer size="sm" />
            <Drawer
              trigger={
                <Button tight color="#7d9a81" icon="plus">
                  See Our Specialties
                </Button>
              }>
              <div className={getElementClass('split-modal')}>
                <div className={getElementClass('split-modal-header')}>
                  Conditions we help address:
                </div>
                <Spacer />
                <div className={getElementClass('split-modal-list')}>
                  <Field name="conditions-list" type="text" />
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </Container>
      <Spacer size="xl" />
      <div id="real-rove-stories" className={getElementClass('stories')}>
        <div className={getElementClass('stories-container')}>
          <Collection
            limit={3}
            name="stories"
            fields={{
              body: 'string',
              name: 'string',
              location: 'string',
              image: 'image@100w',
            }}>
            {(stories) => {
              return (
                <Deck>
                  {stories.map(([story], i) => {
                    const { body, image, location, name } = story;
                    return (
                      <div key={i} className={getElementClass('story')}>
                        <div className={getElementClass('story-header')}>
                          <Field name="rove-stories-title" />
                        </div>
                        <div className={getElementClass('story-body')}>
                          <FitText>{body}</FitText>
                        </div>
                        <div className={getElementClass('story-author')}>
                          <div
                            className={getElementClass('story-author-image')}>
                            {image}
                          </div>
                          <div
                            className={getElementClass('story-author-profile')}>
                            <div
                              className={getElementClass(
                                'story-author-profile-name',
                              )}>
                              {name}
                            </div>
                            <div
                              className={getElementClass(
                                'story-author-profile-location',
                              )}>
                              {location}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Deck>
              );
            }}
          </Collection>
        </div>
      </div>
      <Spacer size="xl" />
      <Container>
        <div id="ai-powered-app" className={getElementClass('app')}>
          <AppScreen length={4}>
            <div className={getElementClass('app-left')}>
              <div className={getElementClass('app-left-top')}>
                <p className="bold">
                  <Field name="ai-powered-title" />
                </p>
                <p className={getElementClass('app-left-top-text')}>
                  <Field name="app-description" />
                </p>
              </div>
              <div className={getElementClass('app-left-bottom')}>
                <AppScreen.Descriptions />
              </div>
            </div>
            <AppScreen.Images />
          </AppScreen>
        </div>
      </Container>
      <Spacer size="xl" />
      <Carousel id="meet-our-team" header="Our Team of Experts" style="buttons">
        <Collection
          name="experts"
          fields={{
            image: 'image@140w',
            name: 'string',
            title: 'string',
            url: 'string',
          }}
          renderItem={(item, data) => {
            const { image, name, title } = item;
            const { url } = data;
            const firstName = data.name.split(' ')[0];
            return (
              <div className={getElementClass('expert')}>
                <div className={getElementClass('expert-image')}>{image}</div>
                <div className={getElementClass('expert-body')}>
                  <p className="bold">{name}</p>
                  <p>{title}</p>
                  <Spacer size="sm" />
                  <a href={url} className={getElementClass('expert-learn')}>
                    Meet {firstName}
                  </a>
                </div>
              </div>
            );
          }}
        />
      </Carousel>
      <Spacer size="xl" />
      <div id="faq" className={getElementClass('faq')}>
        <div className={getElementClass('faq-title')}>
          Frequently Asked Questions
        </div>
        <Spacer />

        <Collection
          name="faq"
          fields={{
            title: 'string',
            body: 'text',
          }}
          renderItem={(item) => {
            const { title, body } = item;
            return (
              <React.Fragment>
                <Expandable className={getElementClass('faq-item')}>
                  <Expandable.Trigger className={getElementClass('faq-header')}>
                    {title}
                  </Expandable.Trigger>
                  <Expandable.Content>{body}</Expandable.Content>
                </Expandable>
              </React.Fragment>
            );
          }}
        />
        <div className={getElementClass('faq-support')}>
          <div className={getElementClass('faq-support-text')}>
            Didn't find the answers you were looking for? Get in touch.
          </div>
          <ExternalLink
            href="mailto:support@rovenutrition.com"
            className={getElementClass('faq-support-link')}>
            Contact Us
          </ExternalLink>
        </div>
      </div>
      <Spacer size="xl" />
      <Container>
        <div className={getElementClass('get-started')}>
          <p className={getElementClass('get-started-header')}>
            Ready to get started?
          </p>
          <p className="lg cardinal balance tight">
            <Field name="get-started-body" />
          </p>
          <Spacer />
          <AppButton to="/register" primary>
            Book a free consultation
          </AppButton>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

function scrollToRove() {
  const el = document.getElementById('discover-rove');
  el.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
}
